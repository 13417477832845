import type MsJSClient from '@wolfejs/ms-api-js/src';
import type {
  PgOrderErrorResponse,
  PgOrderResponse,
  PGOrderSuccessResponse,
} from '@wolfejs/ms-api-js/src/types/orders';
import type { OrderSearchParamsQuery } from '@wolfejs/ms-api-js/src/types/pgcorp';
import type { PGCorpCreateOrderPayload } from '@wolfejs/perfectgift-api-js/src';
import type { PgSingleDataSource } from '@wolfejs/perfectgift-api-medusa';

/**
 * Use to get many orders
 */
export async function getPGCorpOrders(client: MsJSClient, searchParams: OrderSearchParamsQuery) {
  const { data } = await client.orders.getOrders(searchParams);
  return data;
}

/**
 * Use to get all order info for an order
 */
export async function getPGCorpOrderInfo(client: MsJSClient, orderId: string) {
  const { data } = await client.orders.orderInfo(orderId);
  return data;
}

/**
 * Use to get an order's items
 */
export async function getPGCorpOrderItems(client: MsJSClient, orderId: string, searchParams?: OrderSearchParamsQuery) {
  const { data } = await client.orders.orderItems(orderId, searchParams);
  return data;
}

/**
 * Use to get many admin orders
 */
export async function getPGCorpAdminOrders(client: MsJSClient, searchParams: OrderSearchParamsQuery) {
  const { data } = await client.orders.getAdminOrders(searchParams);
  return data;
}

/**
 * Use to get all order info for an admin order
 */
export async function getPGCorpAdminOrder(client: MsJSClient, orderId: string) {
  const { data } = await client.orders.getAdminOrder(orderId);
  return data;
}

/**
 * Use to get an admin order's items
 */
export async function getPGCorpAdminOrderItems(
  client: MsJSClient,
  orderId: string,
  searchParams?: OrderSearchParamsQuery
) {
  const { data } = await client.orders.getAdminOrderItems(orderId, searchParams);
  return data;
}

/**
 * Use to place an order
 */
export async function placeOrder(
  client: MsJSClient,
  payload: PGCorpCreateOrderPayload
): Promise<PgSingleDataSource<PgOrderResponse>> {
  const response = await client.orders.placeOrder(payload);

  const { data } = response;

  if (isSuccessResponse(data)) {
    // Flatten the data field for a success response
    return { data: data.data };
  } else if (isErrorResponse(data)) {
    // Flatten the data field for an error response
    return { data: data };
  } else {
    throw new Error('Unexpected response format');
  }
}

function isSuccessResponse(response: any): response is PGOrderSuccessResponse {
  return (
    typeof response.data === 'object' &&
    'order_id' in response.data &&
    'total' in response.data &&
    'shipping_total' in response.data &&
    'status' in response.data
  );
}

function isErrorResponse(data: any): data is PgOrderErrorResponse {
  return typeof data === 'object' && 'errors' in data;
}
