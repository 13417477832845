import { useQuery } from '@tanstack/react-query';
import { useMsClient } from '../../..';

import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { CatalogSearchParamsQuery } from '@wolfejs/ms-api-js/src/types/pgcorp';
import type { PgPagedDataSource, PgProductEntity } from '@wolfejs/ms-api-js/src/types/products';
import { createPathWithSearchParams } from '@wolfejs/ms-api-js/src/utils/createPathWithSearchParams';
import type { ReactQueryOptions } from '../../types';
import { getProductById, getProducts } from './api';

export function useProducts(
  searchParams: CatalogSearchParamsQuery,
  options?: ReactQueryOptions<PgPagedDataSource<PgProductEntity>>
) {
  const { client } = useMsClient();

  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.listBrands(), searchParams);

  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () =>
      getProducts(client, {
        params: searchParams,
      }), // Extract the data field
  });
}

export function useProduct(id: string) {
  const { client } = useMsClient();

  const key = MsApiEndPoints.pgcorp.getBrandInfo(id);
  return useQuery({
    queryKey: [key],
    queryFn: () => getProductById(client, id),
  });
}
