import { useQuery } from '@tanstack/react-query';
import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { PgOrderEntity, PgOrderItemEntity } from '@wolfejs/ms-api-js/src/types/orders';
import type {
  OrderItemForDownload,
  OrderSearchParamsQuery,
  PgPagedDataSource,
  PgSingleDataSource,
} from '@wolfejs/ms-api-js/src/types/pgcorp';
import type { ReactQueryOptions } from '@wolfejs/ms-api-react/src/types';
import { createPathWithSearchParams } from '@wolfejs/ms-api-react/src/utils/createPathWithSearchParams';
import { getPGCorpAdminOrder, getPGCorpAdminOrderItems, getPGCorpAdminOrders, useMsClient } from '../../..';
import { getPGCorpOrderInfo, getPGCorpOrderItems, getPGCorpOrders } from './api';

/**
 * This hook is used to get orders
 */
export function usePGCorpOrders(
  searchParams: OrderSearchParamsQuery,
  options?: ReactQueryOptions<PgPagedDataSource<PgOrderEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.getOrders(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpOrders(client, searchParams),
  });
}

/**
 * This hook is used to get order info
 */
export function usePGCorpOrderInfo(id: string, options?: ReactQueryOptions<PgSingleDataSource<PgOrderEntity>>) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.pgcorp.getOrderInfo(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpOrderInfo(client, id),
  });
}

/**
 * This hook is used to get order items
 */
export function usePGCorpOrderItems(
  id: string,
  searchParams?: OrderSearchParamsQuery,
  options?: ReactQueryOptions<PgPagedDataSource<PgOrderItemEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.getOrderItems(id), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpOrderItems(client, id, searchParams),
  });
}

/**
 * This hook is used to export order items
 */
export function usePGCorpOrderItemsExport(id: string, options?: ReactQueryOptions<OrderItemForDownload[]>) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.exportOrderItems(id));
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.orders.exportOrderItems(id)).data,
  });
}

/**
 * This hook is used to get orders
 */
export function usePGCorpAdminOrders(
  searchParams: OrderSearchParamsQuery,
  options?: ReactQueryOptions<PgPagedDataSource<PgOrderEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.getAdminOrders(), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpAdminOrders(client, searchParams),
  });
}

/**
 * This hook is used to get order info
 */
export function usePGCorpAdminOrder(id: string, options?: ReactQueryOptions<PgSingleDataSource<PgOrderEntity>>) {
  const { client } = useMsClient();
  const key = MsApiEndPoints.pgcorp.getAdminOrder(id);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpAdminOrder(client, id),
  });
}

/**
 * This hook is used to get order items
 */
export function usePGCorpAdminOrderItems(
  id: string,
  searchParams?: OrderSearchParamsQuery,
  options?: ReactQueryOptions<PgPagedDataSource<PgOrderItemEntity>>
) {
  const { client } = useMsClient();
  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.getAdminOrderItems(id), searchParams);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: () => getPGCorpAdminOrderItems(client, id, searchParams),
  });
}
